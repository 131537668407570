.report {
  border-bottom: 1px solid #cacaca;
  height: 60px;
  &.checked {
    background: #eef3f8; } }

.report-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.report-name {
    padding-right: 5px;
    .reports-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; } } }

.report .btn-theme {
  width: 96%; }

.report .btn-theme.btn-blue {
  background: #0076C0 !important; }

.report a {
  text-decoration: none; }

.preview-modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
