.btn-disabled {
  opacity: 0.5;
}

.reports_wrapper {
  margin-bottom: 40px;
}

.reports_wrapper > .MuiGrid-root.MuiGrid-container:not(.report) {
  border-bottom: 2px solid #000;
}

.reports_wrapper > .MuiGrid-root.MuiGrid-container:not(.report) + hr {
  display: none;
}

.reports_wrapper .MuiGrid-root.MuiGrid-container:not(.report) .MuiTypography-subtitle2 {
  font-size: 12px;
  font-weight: 600;
  position: relative;
  top: 3px;
}

.no-results {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 2rem;
}
