.user-edit {
	margin-bottom: 40px; }

.user-edit .delete-icon {
  right: -36px !important; }

.user-edit-single-col {
	padding-right: 14px; }

.user-edit .profile-info-group .MuiTypography-subtitle1 {
	font-size: 0.875rem;
	color: #000!important;
	font-weight: 600!important; }

.user-edit .profile-info-group .MuiOutlinedInput-input {
	padding-top: 9px;
	padding-bottom: 9px; }
