.preview {
  width: 80vw;
  height: 60vh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem; }

.error-text {
  font-size: 1.5rem; }

.pg-viewer-wrapper {
  width: 100%;
  .sheet-names {
    display: none; }
  .react-grid-Grid {
    border: none; } }

.modal-text {
  font-size: 1.2rem;
  margin-bottom: 1rem; }
