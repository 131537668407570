.title {
  flex-grow: 1; }

.app-header {
  background: #EDF3F9 !important;
  box-shadow: none !important;

  .nav-item {
    text-transform: capitalize;
    margin-right: 10px;
    color: #000;
    font-family: "Roboto", sans-serif;
    padding: 0px 15px;
    cursor: pointer; }

  .header-icon {
        width: 17px;
        height: 19px;
        cursor: pointer; }

  .padlock-icon {
    background-image: url('./../../assets/images/padlock.svg');
    background-size: cover; }

  .user-icon {
    background-image: url('./../../assets/images/user.svg');
    background-size: cover; } }
