.profile {
  width: calc(50vw - 240px);
  margin-left: 240px;
  padding: 24px; }

.profile .btn-theme {
	box-shadow: none!important;
	text-transform: initial;
	font-weight: 600;
	width: auto; }

.profile .copy_block {
	line-height: 1.4; }
