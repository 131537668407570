.support {
  left: 0;
  width: 360px;
  padding: 0px 0%;
  margin-left: calc(50vw - 180px);
  background: rgb(255,255,255);
  height: 90vh;
  margin-top: 5vh;
  position: fixed;
  border-radius: 5px;
  height: auto;
  top: 50%;
  margin-top: 0;
  transform: translateY(-50%);
  padding-bottom: 10px; }

.support .MuiTypography-h5 {
  font-size: 2.25rem; }

.mb-14 {
  margin-bottom: 14px; }

.select-input {
  width: 100%;
  margin-bottom: 14px; }

.support .label_fld {
  margin-bottom: -10px; }

.support .btn-theme {
  box-shadow: none!important;
  text-transform: initial;
  font-weight: 600; }


// .form-input
//   width: 100%
//   display: block
//   margin-bottom: 14px !important
