.range {
  & > .MuiBox-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .MuiFormControl-root {
      width: 100%;
      .MuiInputBase-root {
        &:last-child {
          width: 97%; } } } }
  .MuiInputBase-root {
    &:before {
      display: none; }
    &:after {
      display: none; } } }
