.sidebar-icon {
    min-width: 40px !important;
    margin-left: 20px !important; }

.icon {
      width: 16px !important;
      height: 20px !important;
      cursor: pointer; }

.dashboard-icon {
  background-image: url('./../../assets/images/dashboard.svg');
  background-size: cover; }

.reports-icon {
  background-image: url('./../../assets/images/reports.svg');
  background-size: cover; }

.user-management-icon {
  background-image: url('./../../assets/images/user-management.svg');
  background-size: cover;
  height: 12px !important;
  width: 20px !important; }

.sidebar-support-icon-container {
  width: 50px;
  height: 50px;
  background: #0076C0;
  border-radius: 50%;
  position: absolute;
  bottom: 40px;
  left: 20px;
  cursor: pointer; }

.sidebar-support-icon {
  background-image: url('./../../assets/images/support.svg');
  background-size: cover;
  width: 25px;
  height: 25px;
  margin-left: 12px;
  margin-top: 12px; }
