.register {
  width: 100vw;
  background: #f0f0f0;
  min-height: 100vh;
  display: flex; }

.register .left-col {
  background: #0f0;
  background: linear-gradient(55deg, rgba(240,240,240,1) 0%, rgba(0,118,192,0.3029586834733894) 40%, rgba(40,179,172,0.3029586834733894) 80%, rgba(240,240,240,1) 100%);
  background-image: url('../../../assets/images/register@2x.png');
  background-size: cover; }

.register .form-container {
  margin: auto;
  width: 90%;
  max-width: 600px;
  width: 95%;
  transition: 0.4s;
  transition-timing-function: ease-out; }

.register .form-container.faded-left {
  transform: translate(00px, 60px);
  opacity: 0; }

.register .form-container.faded-right {
  transform: translate(60px);
  opacity: 0; }

.form-group.half-width {
  width: 90%;
  margin-left: 5%; }

.form-group.full-width {
  width: 95%;
  margin-left: 2.5%; }

.form-label {
  margin-bottom: 5px; }

.register-header {
  margin-bottom: 20px !important; }

.cms-btn-primary {
  margin-top: 20px !important;
  width: 320px;
  background: #141414; }

.form-link {
  color: #121212;
  text-decoration: none;
  display: block;
  width: 100%;
  margin-top: 10px;
  font-family: sans-serif;
  font-size: 0.75em; }
