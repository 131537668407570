.filters .btn-theme {
  width: 94%;
  width: auto;
  box-shadow: none!important;
  text-transform: initial;
  font-weight: 600; }

.error-label--download {
  display: inline!important;
  margin-left: 10px; }
