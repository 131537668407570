.report-col {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.report .btn-theme {
  width: 96%; }

.report .btn-theme.btn-blue {
  background: #0076C0 !important; }

.table-header {
  border-bottom: 2px solid #000; }

.table-header .MuiTypography-subtitle2 {
	font-size: 12px;
	font-weight: 600;
	position: relative;
	top: 3px; }

.main-container.user-management {
	margin-bottom: 40px; }

.user-management > .MuiBox-root {
	background: #FFF; }

.user-management .network-graphic {
  opacity: 0.2; }

.MuiTextField-root .MuiOutlinedInput-root {
	border-radius: 4px!important; }

.MuiButton-root.MuiButton-text {
	text-transform: initial;
	font-weight: 600; }

.hidden-checkbox {
  display: none; }
