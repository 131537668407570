.search {
  position: relative;
  width: 100%;
  .MuiFormControl-root {
    width: 100%;
    input {
      padding-right: 45px; } }
  .MuiOutlinedInput-inputMarginDense {
    padding-top: 9px;
    padding-bottom: 10px; }
  .icon-container {
    position: absolute;
    right: 3px;
    top: 3px;
    height: 32px;
    width: 32px;
    border-radius: 5px;
    background: #28B3AC;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      color: white;
      width: 25px !important;
      height: 25px !important;
      cursor: unset !important; } } }
