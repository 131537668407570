.iframe-container.iframe-screen {
  margin-left: 240px; }

@media(min-width:768px) {
  .iframe-container.iframe-screen {
    max-width: 100%; } }

@media(min-width:1600px) {
  .iframe-container.iframe-screen {
    max-width: 2000px; } }

//iframe-container iframe-screen
