.user-approve {
    left: 0;
    width: 480px;
    padding: 0px 0%;
    margin-left: calc(50vw - 260px);
    background: rgb(255,255,255);
    max-height: 90vh;
    margin-top: 5vh;
    position: fixed; }

.btn-add-manufacturer {
    color: #28B3AC !important;
    text-transform: capitalize !important; }

.user-approve .select-input {
    width: calc(100% - 48px); }

.manufacturer-select-container {
  position: relative; }

.manufacturer-select-container .delete-icon {
  position: absolute;
  right: 0;
  color: #28B3AC;
  font-size: 1.777em;
  top: 3px;
  cursor: pointer;
  transition: 0.2s; }

.manufacturer-select-container .delete-icon--disabled {
  opacity: 0.5; }

.manufacturer-select-container .delete-icon:hover {
  opacity: 0.666; }
